import React, { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { RowType, Text, useWalletModal } from 'trustfi-uikit'
import styled from 'styled-components'
import FlexLayout from 'components/layout/Flex'
import { useFarms } from 'state/farms/hooks'
import usePersistState from 'hooks/usePersistState'
import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import {  getFarmAprLP, getFarmAprToken } from 'utils/apr'
import { orderBy } from 'lodash'
import { latinise } from 'utils/latinise'
import Select, { OptionProps } from 'components/Select/Select'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'
import Table from './components/FarmTable/FarmTable'
import FarmTabButtons, { StakeTabButtons } from './components/FarmTabButtons'
import SearchInput from './components/SearchInput'
import { RowProps } from './components/FarmTable/Row'
import { DesktopColumnSchema, ViewMode } from './components/types'
import { UiContainer } from '../../components/layout/StyledUi'
import FarmHead from './components/FarmHeader'
import ToggleView from './components/ToggleView/ToggleView'
import CreatePoolBtn from './components/CreatePool/CreatePoolBtn'
import { UiLuminousBtn } from '../components/UiLayout'
import Circular from '../components/Circular'
import useAuth from '../../hooks/useAuth'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { getWbnbAddress } from '../../utils/addressHelpers'

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0px;
    margin-bottom: 0;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  &.space {
    justify-content: flex-end;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 50%;
    padding: 0;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: 50%;

    > div {
      padding: 0;
    }
  }
`
const StyledBox = styled.div`
  margin-top: 40px;
`
const StyledFlexFit = styled.div`
  display: none;
  flex: 2;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

interface props {
  farmsLPList: Farm[]
  viewModeKey: string
  farmHeadTitle: string
  spaceAddress?: string
  createBtnType?: string
}
const FarmsContent: React.FC<props> = ({
                                         farmsLPList,
                                         viewModeKey,
                                         farmHeadTitle,
                                         spaceAddress,
                                         createBtnType
                                       }) => {
  const { t } = useTranslation()
  const { account,chainId } = useWeb3React()
  const { userDataLoaded,userData,loadFarmsData,commonPoolConfig } = useFarms()

  const [query, setQuery] = useState('')
  const [viewMode, setViewMode] = usePersistState(ViewMode.CARD, viewModeKey)
  const [sortOption, setSortOption] = useState(createBtnType==='Feature'?'featureStartBlock':'startBlock')
  const [farmType,setFarmType] = useState('active')
  const [onlyMyPools,setOnlyMyPools] = useState(false)

  // get space pools
  const farmsLP = spaceAddress? farmsLPList.filter((f)=>f.poolOwner.toLowerCase()===spaceAddress.toLowerCase()):farmsLPList

  const isInactive = farmType==='history'
  const isActive =  farmType==='active'

  // Users with no wallet connected should see 0 as Earned amount
  // Connected users should see loading indicator until first userData has loaded
  const userDataReady = !account || (!!account && userDataLoaded)
  const [stakedOnly, setStakedOnly] = useState(false)

  // get my pool
  const ownerFarms = farmsLP.filter((farm)=>farm.poolOwner === account)
  const showFarmList = onlyMyPools? ownerFarms : farmsLP

  // get active pool list
  const activeFarms = showFarmList.filter((farm) =>  farm.endBlock === 0 )
  const inactiveFarms = showFarmList.filter((farm) =>  farm.endBlock !== 0 )

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => userData.find((ud)=>ud.pid===farm.pid&&new BigNumber(ud.stakeAmount).isGreaterThan(0)),
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => userData.find((ud)=>ud.pid===farm.pid&&new BigNumber(ud.stakeAmount).isGreaterThan(0)),
  )

  const farmsList = useCallback(
    (farmsToDisplay: Farm[]): FarmWithStakedValue[] => {
      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        let totalLiquidity = new BigNumber(farm.poolViewInfo.lpTotalInQuoteToken)
        if(farm.stakedPairUsdtAddress.toLowerCase()  !== farm.rewardPairUsdtAddress.toLowerCase()){
          if(farm.stakedPairUsdtAddress.toLowerCase() ===  getWbnbAddress().toLowerCase()){
            // stakedPairUsdtAddress is wbnb
            totalLiquidity = totalLiquidity.times(new BigNumber(farm.poolViewInfo.stakeUsdtPrice))
          }
          if(farm.rewardPairUsdtAddress.toLowerCase() ===  getWbnbAddress().toLowerCase()){
            // rewardPairUsdtAddress is wbnb
            totalLiquidity = totalLiquidity.div(new BigNumber(farm.poolViewInfo.stakeUsdtPrice))
          }
        }

        // const totalLiquidity = new BigNumber(farm.poolViewInfo.stakeUsdtPrice).times(new BigNumber(farm.poolViewInfo.lpTotalInQuoteToken))
        const farmStakeAmount = new BigNumber(farm.stakeAmount).div(new BigNumber(10).pow(farm.stakeTokenDecimals??18)).toNumber()
        const fApr = farm.poolType===0?
          getFarmAprToken(farm.poolViewInfo.stakeUsdtPrice, farm.poolViewInfo.rewardUsdtPrice,farmStakeAmount,farm.rewardPerBlock)
          :getFarmAprLP(new BigNumber(farm.poolViewInfo.rewardUsdtPrice), totalLiquidity,new BigNumber(farm.rewardPerBlock))
        let apr = isActive ? fApr : 0

        // 增加最高优先级判断逻辑，如果配置强行隐藏apr，则不展示
        const hiddenPoolIds = commonPoolConfig?.hiddenAprPoolIds?.KCC || []
        if (hiddenPoolIds.includes(farm.pid)) {
          apr = undefined
        }
        return { ...farm, apr, liquidity: totalLiquidity }
      })

      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
          return latinise(farm.poolName.toLowerCase()).includes(lowercaseQuery)
            || latinise(farm.stakeTokenSymbol.toLowerCase()).includes(lowercaseQuery)
            || latinise(farm.rewardTokenSymbol.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return farmsToDisplayWithAPR
    },
    [ query, isActive, commonPoolConfig],
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const loadMoreRef = useRef<HTMLDivElement>(null)

  const farmsStakedMemoized = useMemo(() => {
    let farmsStaked = []

    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'startBlock':
          return orderBy(farms, ['poolIsStart','startBlock'], ['desc','desc'])
        case 'featureStartBlock':
          return orderBy(farms, ['startBlock'], ['desc'])
        default:
          return farms
      }
    }

    if (isActive) {
      farmsStaked = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    }
    if (isInactive) {
      farmsStaked = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
    }

    return sortFarms(farmsStaked)
  }, [
    sortOption,
    activeFarms,
    farmsList,
    inactiveFarms,
    isActive,
    isInactive,
    stakedInactiveFarms,
    stakedOnly,
    stakedOnlyFarms,
  ])

  const rowData = farmsStakedMemoized.map((farm) => {
    const curUserData = userData.find((u)=>u.pid===farm.pid)
    const lpLabel = farm.stakeTokenSymbol

    const row: RowProps = {
      apr: {
        value: farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 }),
        multiplier: '1',
        lpLabel,
        rewardTokenPrice: new BigNumber(farm.poolViewInfo.rewardUsdtPrice),
        originalValue: farm.apr,
      },
      farm: {
        image: farm.poolViewInfo && farm.poolViewInfo.stakedLogo,
        label: farm.poolName,
        pid: farm.pid,
        isErrorPool: farm.isErrorPool,
        rewardTokenImg: farm.poolViewInfo && farm.poolViewInfo.rewardLogo,
        stakedSymbol: farm.stakeTokenSymbol && farm.stakeTokenSymbol.toUpperCase(),
        rewardSymbol: farm.rewardTokenSymbol && farm.rewardTokenSymbol.toUpperCase(),
      },
      earned: {
        earnings: getBalanceNumber(new BigNumber(curUserData?curUserData.reward:0),farm.rewardTokenDecimals),
        pid: farm.pid,
      },
      staked: {
        staking: getBalanceNumber(new BigNumber(curUserData?curUserData.stakeAmount:0),farm.stakeTokenDecimals),
        pid: farm.pid,
      },
      actionbtn: farm,
      details: farm,
    }

    return row
  })

  const renderContent = (): JSX.Element => {
    if (viewMode === ViewMode.TABLE && rowData.length) {
      const columnSchema = DesktopColumnSchema

      const columns = columnSchema.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
          switch (column.name) {
            case 'farm':
              return b.id - a.id
            case 'apr':
              if (a.original.apr.value && b.original.apr.value) {
                return Number(a.original.apr.value) - Number(b.original.apr.value)
              }

              return 0
            case 'earned':
              return a.original.earned.earnings - b.original.earned.earnings
            default:
              return 1
          }
        },
        sortable: column.sortable,
      }))

      return <Table data={rowData} columns={columns} userDataReady={userDataReady} />
    }

    return (
      <div>
        <FlexLayout>
          {farmsStakedMemoized.map((farm) => (
            <FarmCard key={farm.pid} farm={farm} account={account} removed/>
          ))}
        </FlexLayout>
      </div>
    )
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const handleChangeTab = (option: string): void => {
    setFarmType(option)
  }
  const handleChangeTabStaked = (option: boolean): void => {
    setStakedOnly(option)
  }

  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <StyledBox>
      <FarmHead title={farmHeadTitle}/>
      <UiContainer>
        <ControlContainer>
          <ViewControls>
            <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
            <FarmTabButtons hasStakeInFinishedFarms={stakedInactiveFarms.length > 0} onTab={handleChangeTab} tabSelect={farmType}/>
            {!spaceAddress &&(
              <StyledFlexFit/>
            )}
            <StakeTabButtons stakedOnly={stakedOnly} onChecked={handleChangeTabStaked}/>
          </ViewControls>
          <FilterContainer className={spaceAddress?'space':''}>
            <LabelWrapper style={{ marginRight: 16 }}>
              <SearchInput onChange={handleChangeQuery} />
            </LabelWrapper>
            {spaceAddress && account && spaceAddress.toLowerCase()===account.toLowerCase() &&(
              <CreatePoolBtn createBtnType={createBtnType}/>
            )}
            {!spaceAddress &&(
              <CreatePoolBtn createBtnType={createBtnType}/>
            )}
            {!spaceAddress &&(
              <UiLuminousBtn className={`flex-1 ${onlyMyPools?'active':''}`}
                             onClick={account?()=>setOnlyMyPools(!onlyMyPools):onPresentConnectModal}
              >
                {t('My Pool')}
              </UiLuminousBtn>
            )}
          </FilterContainer>
        </ControlContainer>
        {renderContent()}
        {!loadFarmsData && (
          <Circular/>
        )}
        {loadFarmsData&&farmsStakedMemoized.length===0 && (
          <div style={{textAlign: 'center',color: '#fff'}}>
            No Result
          </div>
        )}
        <div ref={loadMoreRef} />
      </UiContainer>
    </StyledBox>
  )
}

export default FarmsContent
